import './art.css';
import artHeader from "/Users/ethantam/Desktop/etweb/src/assets/artHeader.png";
import ArtExperience from '/Users/ethantam/Desktop/etweb/src/components/art/artExperience.js';
import ArtExperienceVideo from '/Users/ethantam/Desktop/etweb/src/components/art/artExperienceVideo.js';
import EtsyPic from "/Users/ethantam/Desktop/etweb/src/assets/Etsy.png";
import IITBridgePic from "/Users/ethantam/Desktop/etweb/src/assets/IITBridgePic.png";
import ASVC from "/Users/ethantam/Desktop/etweb/src/assets/ASVC.jpeg";
import Kodama from "/Users/ethantam/Desktop/etweb/src/assets/ProjectionMapping.png";
import Bubbles from "/Users/ethantam/Desktop/etweb/src/assets/Bubbles1.png";
import Star from "/Users/ethantam/Desktop/etweb/src/assets/Star.png";


function art() {
    return (
      <div className="art">
        <div className="artHeaderDescription">
          <img className="artHeader" src={artHeader} alt="chopper"></img>
          <ArtExperienceVideo name="Demo Reel / Portfolio" time="2025" title=""  text="A demo reel of my best work for far! Here's a link to view it on Youtube: https://www.youtube.com/embed/NwFRVDcApRA?si=lLuu6PBRLou0d5Nd" video="https://www.youtube.com/embed/NwFRVDcApRA?si=lLuu6PBRLou0d5Nd"/>
          <ArtExperience name="Kodama Face Mapping" time="2024" image={Kodama}  text="For this project, I implemented a real-time face tracker using clmtrackr and p5.js to bring Kodama, a character that conveys deep emotions without facial expressions, to life. By capturing a live webcam feed and tracking facial landmarks like the eyes and mouth, Kodama subtly responds to user movements. This setup dynamically aligns Kodama’s features, inviting viewers to interpret its emotions and reflect on the importance of inner feeling, adding a layer of mystery and emotional depth central to my art style." />
          <ArtExperience name="Bubbles!" time="2024" image={Bubbles}  text="In this project, I used JavaScript and p5.js to create an interactive, immersive display of floating bubbles. Each bubble moves fluidly with gentle, noise-based drifting, bouncing off the canvas edges, and users can click and drag them around for a dynamic experience. The bubbles subtly change in size, adding to their lifelike motion, while layered, semi-transparent ellipses give each one a soft, glowing effect. Occasionally, new bubbles appear, keeping the display visually engaging and allowing users to feel immersed in a fluid, organic environment." />
          <ArtExperience name="Constellation Drawer" time="2024" image={Star}  text="In this project, I used JavaScript and p5.js to create an interactive night sky scene with twinkling stars and user-generated constellations. Users can click on the canvas to place points and draw their own constellations, with each completed set of four points saved as a unique constellation with a 3D Bezier Curve. The combination of twinkling stars, a dynamic background, and interactive constellation drawing makes for an engaging and immersive stargazing experience." />
          
          <ArtExperienceVideo name="Broken Heart Algorithm" time="2024" title="Director/Project Manager"  text="Created an animated short for the 2024 24-Hour Animation Challenge, exploring the theme of AI (good or bad). For the project, we used Maya for modeling, Blender for shading and animation, After Effects for compositing, and Premiere Pro for editing and adding visual effects." video="https://www.youtube.com/embed/0zljpSqJgHc?si=L5TI2EHVvSTTPqS9"/>
          <ArtExperienceVideo name="Shooting Snail Animation" time="2023" title="Director/Project Manager"  text="UCBugg is an introductory animation course offered at UC Berkeley that provides students with the opportunity to develop their skills in animation software and techniques while learning about the animation industry. As the project manager in my group, we created a captivating animated short about a snail who strives to become a star. Throughout the course, we explored various animation techniques and software, and were able to apply our newfound skills to create a visually stunning and engaging animated story." video="https://www.youtube.com/embed/deqK3hyqebQ"/>
          <ArtExperienceVideo name="Happy Pickle Animation Trailer" time="2023" title="Project Manager"  text="UCBugg advanced animation course offered at UC Berkeley. In this project, I'm working with a team to create a short about a pickle who works a 9 to 5 but with a tragic twist. I worked mainly on the set production, modeling, animating, and shading. Full short coming soon!" video="https://www.youtube.com/embed/DSgmxClFaQM?si=ozyC7RfRLgj95rLp"/>
          <ArtExperience name="Etsy" time="2020 - 2022" title="Producer/Manager" image={EtsyPic}  text="My Etsy shop is the result of months of fundraising and hard work, where I conceptualize product ideas and execute the design. Every product is carefully prepared for the shop through photography, cost analysis, and online listing to ensure that each piece is unique and of the highest quality. As someone who feels strongly about the social climate, I donate 20% of my profits to nonprofit organizations that align with the themes of my stickers. With a focus on quality and timely shipping, I meticulously organize, package, and ship orders for customers to ensure the best possible shopping experience. " />
          <ArtExperience name="Arts Student Voice Committee" time="2018-2020" image={ASVC} title="Intern" text="As an intern for ASVC, I played a pivotal role in advancing the growth of the Arts Student Voice Committee, contributing to its expansion and development. This involved close collaboration with the DAE to formulate and implement strategies aimed at enhancing arts education throughout Chicago Public Schools. As part of this effort, I actively participated as a panelist and volunteer at five educator professional development conferences/exhibitions. Additionally, I engaged in collective committee reviews of DAE documentation, leveraging insights and expertise to drive impactful initiatives forward. Furthermore, I contributed to the orchestration of the annual All City Visual Arts Exhibitions and Teacher's Appreciation Week Exhibitions, ensuring the successful execution of these important events."/>
          <ArtExperience name="IIT Bridge Building" time="2018-2020" image={IITBridgePic} text="From an early age, growing up in a family passionate about construction, I've been deeply fascinated by the intricate designs of buildings and architecture. The IIT Bridge Building contest offered a rare chance to merge my creative talents in design with my family's expertise in efficient structures, providing a profound insight into the artistic nature that underpins construction, and a deeper understanding of my father and brother's work."/>
          </div>
      </div>
    );
  }
  
  export default art;